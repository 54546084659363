<template>
  <div class="page-layout curricula">

    <HeaderIntroImage v-if="pageData" central underlined :headerMetadata="pageData">
      <br>
      <p class="teaser" 
        v-if="pageData.attributes.field_page_main_content"
        v-html="pageData.attributes.field_page_main_content.value">
      </p>
      <br>
      <button class="btn slim"><span>Submit</span></button>
    </HeaderIntroImage>

    <div class="curricula-grid" id="grid">

      <div :class="{'info-panel': true ,'hidden': !this.showPanel}">
        <button class="close-button" @click="closePanel"></button>

        <h2 class="gray">{{ this.selectedCountry }}</h2>

        <p class="teaser" v-html="this.selectedDescription"></p>

        <p class="blue update">Curriculum implemented in <span class="bold">{{ lastImplemented }}</span></p>

        <p class="blue update">Entry updated in <span class="bold">{{ lastUpdate }}</span></p>

        <br>
        <hr>

        <p v-if="selectedCurricula.filter(r => 
          r.attributes.field_curricula_references && r.attributes.field_curricula_references.value).length" 
          class="label">REFERENCES</p>
        <div class="references">
          <p v-for="(curr, ind) in selectedCurricula.filter(r => 
            r.attributes.field_curricula_references && r.attributes.field_curricula_references.value)" 
          :key="'curriculum'+ind"
          class="reference list-2" v-html="curr.attributes.field_curricula_references.value"></p>
        </div>

        <p class="label" v-if="this.mergeMembers.filter(m => isVisible(m)).length">CONTRIBUTORS</p>
        <ul class="list-1 contributors">
          <li v-for="(m, ind) in this.mergeMembers.filter(m => isVisible(m))" :key="'contrib'+ind">

            <router-link v-if="isClickable(m)" class="person-link plain" :to="plink(m)">
              {{ m.attributes.title }}
            </router-link>
            <p v-if="!isClickable(m)" class="person-link">
              {{ m.attributes ? m.attributes.title : m }}
            </p>
            
          </li>
        </ul>
        

<!--         <div class="curriculum" v-for="(curr, ind) in selectedCurricula" :key="'curriculum'+ind" >

          <h4 class="icon-right read-more close-curriculum" ref="curriculum-title"
            @click="closeCurriculum(ind)">{{ curr.attributes.title }}</h4>

          <div class="curr-content closed" ref="curriculum" :id="'curriculum'+ind">
            <p class="blue teaser" v-if="curr.attributes.field_curriculum_implemented_in">
              Curriculum implemented in <span class="bold">{{ curr.attributes.field_curriculum_implemented_in }}</span></p>
            <p class="blue teaser" v-if="curr.attributes.field_entry_updated_in">
              Entry updated in <span class="bold">{{ curr.attributes.field_entry_updated_in }}</span></p>

            <hr v-if="curr.attributes.field_entry_updated_in || curr.attributes.field_curriculum_implemented_in">

            <p v-if="curr.attributes.field_description" 
              v-html="curr.attributes.field_description.value"></p>

            <p v-if="curr.attributes.field_curricula_references" 
              class="label">REFERENCES</p>
            <p v-if="curr.attributes.field_curricula_references"
              v-html="curr.attributes.field_curricula_references.value"></p>

            <p v-if="numVisibleMembers(curr)" class="label">CONTRIBUTORS</p>
            <ArticlesGrid class="slim">
              <MemberCardMin v-for="(member, ind) in curr.field_ref_member" :key="'member'+ind" :routesData="routesData" :memberData="member"/>
            </ArticlesGrid>

          </div>
        </div> -->
      </div>

      <div :class="{'filters-column': true, 'hidden': !showFilters}">
        
        <div class="inner">
          <div class="section">
            <h2>Quantum Physics Topics</h2>
            <button class="btn light slim" @click="reset"><span class="teaser bold icon-right reset">CLEAR ALL</span></button>
          </div>
          
          <div :class="['box icon-left', 'box'+index, selectedTopic.includes(index) ? 'selected' : '']" 
            
            v-for="(area, index) in this.qtTopics" :key="'area'+index"
            @click="selectTopic(index)">

            <div class="title">
              <h4>{{ area.title }}</h4>
              <button :class="{'slim btn': true, 'border': selectedTopic.includes(index)}">
                <span :class="{'teaser': true, 'bold': selectedTopic.includes(index)}">
                  {{ selectedTopic.includes(index) ? 'SELECTED' : 'SELECT' }}</span>
              </button>
            </div>
            <div class="content">
              <p :class="{'teaser': true, 'blue': selectedTopic.includes(index)}" 
                v-for="(topic, ind) in area.topics" :key="'topic'+index+'_'+ind">
                {{ topic.substr(topic.indexOf(' '), topic.length) }}
              </p>
            </div>
          </div>

        </div>
      </div>

      <div :class="{'filters-button': true}">
        <button :class="{'btn slim': true, 'hidden': showFilters}" @click="openFilters"><span class="teaser icon-left funnel">Filter by theme</span></button>
        <button :class="{'btn slim apply': true, 'hidden': !showFilters}" @click="openFilters"><span class="teaser icon-right arrow">Apply filters</span></button>
      </div>

      <div :class="{'map': true, 'hidden': showFilters}" ref="map"></div>
    </div>

  </div>
</template>

<script>
import mapboxgl from 'mapbox-gl'
mapboxgl.accessToken = 'pk.eyJ1IjoiaW50ZXJmYXNlIiwiYSI6ImNqYW8zZGJkeTRjNXQzNHIxYmJpMWF6MnkifQ.TfWAUiqo6rDYVGid30Q6sA'
import 'mapbox-gl/dist/mapbox-gl.css'

import HeaderIntroImage from '../components/layout-blocks/HeaderIntroImage.vue'
//import ArticlesGrid from '../components/layout-blocks/ArticlesGrid.vue'
//import MemberCardMin from '../components/layout-blocks/MemberCardMin.vue'
import { fetchSinglePage, fetchNodes } from '../libs/drupalClient'

export default {
  name: 'eu-school-curricula',
  components: {
    HeaderIntroImage,
    //MemberCardMin,
    //ArticlesGrid
  },
  props: {},
  data: () => {
    return {
      pageData: null,
      currentPageID: "75243611-c9dc-4dc6-a724-37dc18c106ee",
      map: null,
      mapData: null,
      selectedCode: '',
      //selectedISO3: '',
      selectedCountry: '',
      selectedDescription: '',  
      countryKeys: [],
      darkMapColor: '#071150',
      baseMapColor: '#0f1b66',
      lightMapColor: 'rgb(220, 220, 220)',
      hoverMapColor: 'rgb(250, 250, 250)',
      showPanel: false,
      showFilters: false,
      qtTopics: [
        {
          title: 'Fundamental QP Principles',
          topics: [
            'Q5 Wave-particle duality or complementarity', 
            'Q8 Heisenberg\'s uncertainty principle', 
            'Q9 Probabilistic or statistical predictions', 
            'Q14 Pauli exclusion principle', 
            'Q15 Entanglement'
          ]
        }, {
          title: 'Phenomena and applications',
          topics: [
            'Q1 Blackbody radiation', 
            'Q3 Discrete energy levels (line spectra)', 
            'Q4 Interactions between light and matter', 
            'Q6 Matter waves, quantitative (de Broglie)', 
            'Q7 Technical applications', 
            'Q12 Tunneling'
          ]
        }, {
          title: 'Atomic theory',
          topics: [
            'Q2 Bohr atomic model',
            'Q3 Discrete energy levels (line spectra)',
            'Q11 One dimensional model or potential well',
            'Q13 Atomic orbital model',
            'Q14 Pauli exclusion principle'
          ]
        }, {
          title: 'Wave function or other mathematic representations',
          topics: [
            'Q12 Tunneling',
            'Q16 Schrödinger equation',
            'Q17 Detection probability as square of the magnitude of the wave function or square of the phasor length in the sum over path approach'
          ]
        }, {
          title: 'Philosophical aspects of QP',
          topics: [
            'Q5 Wave-particle duality or complementarity',
            'Q10 Philosophical or epistemological consequences explicitly mentioned as a learning outcome',
            'Q15 Entanglement'
          ]
        }
      ],
      selectedTopic: []
    }
  },
  computed: {
    lang() {
      return this.$store.state.currentLang
    },
    defaultLang() {
      return this.$store.state.defaultLang
    },
    loggedUser() {
      return this.$store.getters.getLogged
    },
    routesData() {
      return this.$store.state.routesData
    },
    selectedCurricula() {
      var curss = []
      if(this.selectedCountry) {
        curss = this.mapData.filter(m => {
          return (m.field_school_curr_country.attributes.name == this.selectedCountry)
        })
      }
      return curss
    },
    lastUpdate() {
      var last = this.selectedCurricula
        .filter(c => c.attributes.field_entry_updated_in)
        .sort((a, b) => {
          return a.attributes.field_entry_updated_in > b.attributes.field_entry_updated_in ? -1 : 1
        })[0]

      if(last)
        return last.attributes.field_entry_updated_in
      else return ''
    },
    lastImplemented() {
      var last =  this.selectedCurricula
        .filter(c => c.attributes.field_curriculum_implemented_in)
        .sort((a, b) => {
          return a.attributes.field_curriculum_implemented_in > b.attributes.field_curriculum_implemented_in ? -1 : 1
        })[0]

      if(last)
        return last.attributes.field_curriculum_implemented_in
      else
        return ''
    },
    mergeMembers() {
      var mmbs = []
      this.selectedCurricula.forEach(c => {
        c.field_ref_member.forEach(m => mmbs.push(m))
        if(c.attributes.field_external_contributor)
          mmbs.push(c.attributes.field_external_contributor)
      })
      return mmbs
    }
  },
  methods: {
    numVisibleMembers(curr) {
      return curr.field_ref_member.filter(m => {
        return this.loggedUser || (!this.loggedUser && m.attributes.field_member_data_visibility && m.attributes.field_member_data_visibility!='None')
      }).length
    },
    openFilters() {
      this.showFilters = !this.showFilters

      window.scrollBy({
        behavior: 'smooth',
        top: document.getElementById('grid').getBoundingClientRect().top - (window.innerWidth >= 1200 ? 100 : 60)
      })

      //console.log(this.showFilters)
    },
    /* closeCurriculum(ind) {
      this.$refs.curriculum[ind].classList.toggle('closed')
      this.$refs['curriculum-title'][ind].classList.toggle('upside')
    }, */
    closePanel() {
      this.showPanel = false
      document.body.classList.remove('noscroll')
      //this.$refs.curriculum.forEach(c => c.classList.add('closed'))
      //this.$refs['curriculum-title'].forEach(c => c.classList.remove('upside'))
    },
    reset() {
      this.selectedTopic = []
      this.computeCountryKeys(this.mapData)
      
      if(this.map) {
        this.map.removeLayer('countries-data')
        this.colorCountries()
      }

    },
    isVisible(m) {
      if(m.attributes)
        return this.loggedUser || (!this.loggedUser && m.attributes.field_member_data_visibility && m.attributes.field_member_data_visibility!='None')
      else 
        return true
    },
    isClickable(m) {
      if(m.attributes)
        return this.loggedUser || (!this.loggedUser && m.attributes.field_member_data_visibility && m.attributes.field_member_data_visibility=='Full')
      else
        return false
    },
    plink(m) {
      if(m.attributes) {
        var link = this.routesData.filter(r => r.nid == m.attributes.drupal_internal__nid)
        //console.log(link)
        if(link.length > 0)
          return link[0].path
      } else return '/not-found'
    },
    selectTopic(ind) {
      if(this.selectedTopic.includes(ind))
        this.selectedTopic.splice(this.selectedTopic.indexOf(ind), 1)
      else 
        this.selectedTopic.push(ind)

      window.scrollBy({
        behavior: 'smooth',
        top: document.getElementById('grid').getBoundingClientRect().top - (window.innerWidth >= 1200 ? 100 : 60)
      })

      var newMapData = []

      if(this.selectedTopic.length)
         newMapData = this.mapData.filter(curr => {
          var found = false
          curr.attributes.field_topics.forEach(t => {
            this.selectedTopic.forEach(st => {
              if(this.qtTopics[st].topics.includes(t))
                found = true
            })
            
          })
          return found
        })

      if(!newMapData.length && !this.selectedTopic.length) {
        this.computeCountryKeys(this.mapData)
      } else
        this.computeCountryKeys(newMapData)

      if(this.map) {
        if(this.map.getLayer('countries-data'))
          this.map.removeLayer('countries-data')
        
        if(!(this.selectedTopic.length && !newMapData.length))
          this.colorCountries()
      }

    },
    computeCountryKeys(data) {
      var gbc = []

      data.forEach(curr => {
        //var c = this.$store.getters.getISO3(curr.attributes.field_country[0].country)
        var c = curr.field_school_curr_country.attributes.name
        if(!gbc[c]) {
          gbc[c] = []
          gbc[c].push(curr)
        }
        else 
          gbc[c].push(curr)
      })

      this.countryKeys = Object.entries(gbc).map(function(value) {
        return [value[0], value[1].length]
      })
      //console.log('compute country keys', this.countryKeys, gbc)

      return gbc
    },
    colorCountries() {
      // Build a GL match expression that defines the color for every vector tile feature
      // Use the ISO 3166-1 alpha 3 code as the lookup key for the country shape
      //const matchExpression = ['match', ['get', 'iso_3166_1_alpha_3']];
      const matchExpression = ['match', ['get', 'name_en']];
      
      var max = 0
      for(const row of this.countryKeys)
        if(row[1]>max) max=row[1]

      for(const row of this.countryKeys) {
        // Convert the range of data values to a suitable color
        const alpha = (row[1] / max) + 0.2;
        let color = `rgba(220, 220, 220, ${alpha})`
        
        matchExpression.push(row[0], color);

        /* NETHERLANDS EXCEPTION */
        if(row[0] == 'Netherlands The')
          matchExpression.push('Netherlands', color)
      }

      // Last value is the default, used where there is no data
      //matchExpression.push('rgb(255, 255, 255)');
      matchExpression.push(this.baseMapColor)
      
      // Add layer from the vector tile source to create the choropleth
      // Insert it below the 'admin-1-boundary-bg' layer in the style 
      this.map.addLayer({
        'id': 'countries-data',
        'type': 'fill',
        'source': 'countries',
        'source-layer': 'country_boundaries',
        'paint': {
          'fill-color': matchExpression
        }
      }, 'state-stroke');
    },
    buildMap() {
      var vm = this

      if(this.map)
        this.map.remove()

      this.map = new mapboxgl.Map({
        container: this.$refs.map,
        style: 'mapbox://styles/interfase/ckz5m7u5y001415qo3xjqm2q9?fresh=true',
        zoom: 2.9,
        minZoom: 2,
        maxZoom: 7,
        center: [5, 52],
        interactive: false
      })

      this.map.scrollZoom.disable();
      const nav = new mapboxgl.NavigationControl();
      this.map.addControl(nav, 'bottom-left');

      this.map.on('load', () => {
        this.map.addSource('countries', {
          type: 'vector',
          url: 'mapbox://mapbox.country-boundaries-v1'
        });

        this.map.addLayer({
          'id': 'state-fills',
          'type': 'fill',
          'source': 'countries',
          "source-layer": "country_boundaries",
          'paint': {
            'fill-color': vm.hoverMapColor,
            'fill-opacity': [
              'case',
              ['boolean', ['feature-state', 'hover'], false],
              1,
              0
            ]
          }
        }, 'country-labels');

        this.map.addLayer({
          'id': 'state-stroke',
          'type': 'line',
          'source': 'countries',
          "source-layer": "country_boundaries",
          'paint': {
            'line-color': vm.darkMapColor,
            'line-width': 0.3
          }
        }, 'state-fills');

        this.map.addLayer({
          'id': 'state-halo',
          'type': 'line',
          'source': 'countries',
          "source-layer": "country_boundaries",
          'paint': {
            'line-color': 'rgb(180, 180, 180)',
            'line-opacity': 0.5,
            'line-width': [
              'case',
              ['boolean', ['feature-state', 'hover'], false],
              1,
              0
            ]
          }
        }, 'country-labels');

        this.colorCountries()
            
      })

      this.map.on('click', function(e) {
        var point = vm.map.queryRenderedFeatures(e.point, {
          layers: ['state-fills']
        })[0]

        //if it finds a country under hit point
        if(point) {

          vm.countryKeys.forEach(c => {
            if( c[0] == point.properties.name_en 
            /* NETHERLANDS EXCEPTION */
            || (c[0] == 'Netherlands The' && point.properties.name_en == 'Netherlands') ) {
              vm.showPanel = true
              document.body.classList.add('noscroll')

              vm.selectedCountry = point.properties.name_en
              /* NETHERLANDS EXCEPTION */
              if(c[0] == 'Netherlands The')
                vm.selectedCountry = 'Netherlands The'

              window.scrollBy({
                behavior: 'smooth',
                top: document.getElementById('grid').getBoundingClientRect().top - (window.innerWidth >= 1200 ? 100 : 60)
              })
            }
          })

          vm.selectedDescription = (
            vm.selectedCurricula[0].field_school_curr_country.attributes.description ? 
            vm.selectedCurricula[0].field_school_curr_country.attributes.description.value : 
            '')

        }
      })

      let hoveredStateId = null
      this.map.on('mousemove', function(e) {
        var point = vm.map.queryRenderedFeatures(e.point, {
          layers: ['state-fills']
        })

        if(hoveredStateId !== null) {
          vm.map.setFeatureState(
            { source: 'countries', sourceLayer: 'country_boundaries', id: hoveredStateId },
            { hover: false }
          );
        }

        if(point.length) {
          let found = false
          vm.countryKeys.forEach(d => {

            if(d[0] == point[0].properties.name_en
              /* NETHERLANDS EXCEPTION */
              || (d[0] == 'Netherlands The' && point[0].properties.name_en == 'Netherlands'))
              found = true
          })

          if(found) {
            hoveredStateId = point[0].id
            vm.map.setFeatureState(
              { source: 'countries', sourceLayer: 'country_boundaries', id: hoveredStateId },
              { hover: true }
            )
          }
        }
                  
      });
                
      // When the mouse leaves the state-fill layer, update the feature state of the
      // previously hovered feature.
      this.map.on('mouseleave', 'state-fills', () => {
        if (hoveredStateId !== null) {
          this.map.setFeatureState(
            { source: 'countries', sourceLayer: 'country_boundaries', id: hoveredStateId },
            { hover: false }
          );
        }
        hoveredStateId = null;
      });
    }
  },
  async mounted() {
    this.selectedCode = 'total'

    await fetchNodes('school_curricula', {
      include: ['field_ref_member', 'field_school_curr_country'],
      nested_include: ['field_ref_member.field_photo', 'field_ref_member.field_organization']
    }).then(res => {

      this.mapData = res
      //console.log('this.mapData', this.mapData)

      this.computeCountryKeys(res)
    })

    await this.buildMap()

    await fetchSinglePage(this.currentPageID, {})
    .then(res => {
      this.pageData = res
      //console.log('pagedata', this.pageData)
    })
  },
  destroyed() {
    document.body.classList.remove('noscroll')
  },
  watch: {}
}
</script>

<style lang="scss" scoped>
@import "../styles/variables.scss";

.hidden { 
  pointer-events: none;
  display: none; 
}

.curricula-grid {
  position: relative;
  height: fit-content;
  width: 100%;
  overflow-x: hidden;
}

.map {
  height: calc(100vh - 60px);
  width: 100%;
  z-index: 5;
}

.filters-button {
  position: absolute;
  z-index: 7;
  bottom: 4rem;
  left: 50%;
  transform: translateX(-50%);
  width: fit-content;

  .apply { transform: translateY(1rem); }
}

.filters-column {
  height: calc(100vh - 60px);
  overflow-y: scroll;

  .inner {
    padding: 0 1rem;
  }

  .inner .section {
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    h2 { font-size: 1.5rem; }
    button { 
      height: fit-content; 
      margin: 0 0 0 auto; 
    }
  }

  .inner .box {
    padding: 1rem 1rem;
    margin: 1rem 0;
    border: 1px $primaryColor solid;
    border-radius: 10px;

    &.icon-left {
      padding-left: 3rem;
      &:before { 
        left: .75rem;
        top: 4rem; 
        transform: none; 
        width: 1.75rem;
        height: 1.75rem;
      }

      &.box0:before { background: url(../assets/topic0.svg); background-size: contain; background-repeat: no-repeat; }
      &.box1:before { background: url(../assets/topic1.svg); background-size: contain; background-repeat: no-repeat; }
      &.box2:before { background: url(../assets/topic2.svg); background-size: contain; background-repeat: no-repeat; }
      &.box3:before { background: url(../assets/topic3.svg); background-size: contain; background-repeat: no-repeat; }
      &.box4:before { background: url(../assets/topic4.svg); background-size: contain; background-repeat: no-repeat; }
    }

    &.selected {
      background: $lightBlue;
      border-color: $primaryColor;
    }

    &:hover {
      background: darken($lightBlue, 7%);
      border-color: darken($lightBlue, 7%);
      cursor: pointer;
    }

    .title {
      display: flex;
      flex-direction: column-reverse;
      justify-content: flex-start;
      h4 { 
        width: 100%; 
        font-size: 1.25rem;
      }
      button { width: fit-content; height: fit-content; margin: 0 0 0 auto; }
    }
    .content {
      p {
        margin: 0;
        line-height: 1.5em;
      }
    }
  }
}

.info-panel {
  position: fixed;
  display: block;
  top: 60px;
  right: 0;
  opacity: 1;
  z-index: 9;
  width: 100%;
  height: calc(100vh - 60px);
  overflow-y: scroll;
  background: #fff;
  transition: all 500ms ease;

  &.hidden {
    right: -100%;
    opacity: 0;
    transition: all 500ms ease;
    display: block;
    .close-button { 
      right: -100%; 
      transition: all 500ms ease;
    }
  }

  .close-button {
    width: 60px;
    height: 60px;
    position: fixed;
    z-index: 8;
    top: 60px;
    right: 0;
    
    border: none;
    outline: none;
    background: url(../assets/icon_close_grey-w.svg) content-box, #333 padding-box;
    background-repeat: no-repeat;
    background-size: contain;
    box-sizing: border-box;
    padding: 1em;
    transition: all 500ms ease;
  }

  h2 {  margin-top: 70px; }
  .update { margin: 0; }

  .references {
    li { padding-left: 3rem; }
    .reference {
      &:first-child:before { left: 1.5rem !important; }
      ::v-deep p { margin: 0; font-size: 16px; line-height: 1.5em; }
    }

  }
  .contributors {
    margin-bottom: 2rem; 
    li *:first-child { margin: 0; }
    .person-link {
      font-family: $primaryFont;
      color: $primaryColor;
      font-size: 1.2em;
    }
    a.person-link:hover {
      color: $middleBlue;
    }
  }
  > * { padding: 0 1rem; }

  .close-curriculum {
    padding: .5rem 1rem;
    margin: 0;
    &:hover {
      cursor: pointer;
      background: $lightBlue;
    }
  }

  .curriculum { 
    margin-bottom: .5rem;
    background: #f5f5f5;
  }

  .curr-content { 
    padding: 1rem;
    //margin-bottom: 2rem;
    
    p { margin: 0; }
    p ::v-deep p { font-size: 16px; line-height: 21px; }
    hr { margin: 1rem 0; }

    &.closed {
      height: 0;
      display: none;
      margin-bottom: 0;
      overflow: hidden;
    }
  }

  .read-more:before { right: 1em; }
  .upside:before { top: 1.25em; }
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
  .info-panel {
    padding: 0 1rem;
    .curriculum { margin-bottom: 1rem; }
  }

  .info-panel, .filters-column {
    &::-webkit-scrollbar {
      width: 10px;
      background: #fff;
    }
    &::-webkit-scrollbar-thumb {
      background: $lightBlue;
      border-radius: 10px;
    }
  }
}

@media (min-width: 1024px) {
  .filters-button { display: none; }

  .curricula-grid { 
    display: flex;
    flex-direction: row;
  }

  .info-panel {
    padding: 0 15%; 
    //padding: 0 5%;
    width: 100%;
    .close-button {
      width: 80px;
      height: 80px;
      padding: 1.75em;
    }
    h2 { margin-top: 90px; }
    .curr-content, .close-curriculum {
      padding-left: 2rem;
      padding-right: 2rem;
    }
    .curr-content { padding-bottom: 2rem; }
  }

  .filters-column, .map {
    display: block;
    &.hidden { 
      display: block; 
      pointer-events: all;
    }
  }

  .filters-column { width: 33.33%; }
  .map { width: 66.66%; }

  .filters-column .inner {
    /* .section {
      button { margin: 0 0 0 auto; }
      h2 { margin-bottom: 0; }
    } */
    .box .title {
      flex-direction: column;
      margin-bottom: 1rem;
      h4 { margin-top: 0; }
    }
    .box.icon-left:before { top: 1.25rem; }
  }
}

@media (min-width: 1200px) {
  .map, .filters-column, .info-panel {
    height: calc(100vh - 100px);
  } 
  .info-panel, 
  .info-panel .close-button { 
    top: 100px; 
  }

  .filters-column .inner {
    .section {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      button { width: fit-content; margin: 1rem 0; }
      h2 { line-height: 1.5em; margin: 1rem 0; }
    }
    .box .title { 
      flex-direction: row;
      align-items: center;
      h4 { width: 63% }
      button { width: 25%; }
    }
  }
}

@media (min-width: 1500px) {
  .info-panel {
    padding: 0 20% 10% 20%;
    //padding: 0 5% 10% 5%;
  }
}

</style>